// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "r_rM d_bD";
export var storyRowWrapper = "r_hx d_hx d_bK";
export var storyLeftWrapper = "r_rN d_bz d_bP";
export var storyWrapperFull = "r_rP d_cD";
export var storyWrapperFullLeft = "r_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "r_mS d_hy";
export var storyLeftWrapperCenter = "r_rQ d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "r_rR d_hF";
export var storyHeader = "r_rS d_hD d_w d_cs";
export var storyHeaderCenter = "r_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "r_hB d_hB d_by d_dw";
export var storyBtnWrapper = "r_rT d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "r_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "r_qH d_fg d_Z";
export var imageWrapperFull = "r_qJ d_w d_H d_bf d_Z";
export var SubtitleSmall = "r_qd s_qd s_rV s_r5";
export var SubtitleNormal = "r_qf s_qf s_rV s_r6";
export var SubtitleLarge = "r_qg s_qg s_rV s_r7";
export var textLeft = "r_dv";
export var textCenter = "r_dw";
export var textRight = "r_dx";