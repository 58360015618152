// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "v_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "v_fR d_fR d_bz d_bJ";
export var menuDesign6 = "v_s0 d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "v_s1 d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "v_s2 d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "v_s3 d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "v_s4 d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "v_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "v_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "v_s5";
export var navbarItem = "v_nb d_bx";
export var navbarLogoItemWrapper = "v_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "v_s6 d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "v_s7 d_gd d_by d_Z d_bs";
export var burgerToggle = "v_s8 d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "v_s9 d_gd d_by d_Z d_bs";
export var burgerInput = "v_tb d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "v_tc d_f3 d_w d_H";
export var burgerLine = "v_td d_f1";
export var burgerMenuDesign6 = "v_tf d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "v_tg d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "v_th d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "v_tj d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "v_tk d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "v_tl d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "v_tm d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "v_tn d_bC d_bP";
export var compact = "v_tp";
export var navDivided = "v_tq";
export var staticBurger = "v_tr";
export var menu = "v_ts";
export var navbarDividedLogo = "v_tt";
export var nav = "v_tv";