// extracted by mini-css-extract-plugin
export var lbContainerOuter = "y_vl";
export var lbContainerInner = "y_vm";
export var movingForwards = "y_vn";
export var movingForwardsOther = "y_vp";
export var movingBackwards = "y_vq";
export var movingBackwardsOther = "y_vr";
export var lbImage = "y_vs";
export var lbOtherImage = "y_vt";
export var prevButton = "y_vv";
export var nextButton = "y_vw";
export var closing = "y_vx";
export var appear = "y_vy";