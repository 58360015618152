// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_rs d_gv d_cs";
export var heroHeaderCenter = "q_gw d_gw d_cs d_dw";
export var heroHeaderRight = "q_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "q_rt d_gr d_cw";
export var heroParagraphCenter = "q_gs d_gs d_cw d_dw";
export var heroParagraphRight = "q_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "q_rv d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "q_rw d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "q_rx d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "q_ry d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "q_rz d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "q_rB s_rB";
export var heroExceptionNormal = "q_rC s_rC";
export var heroExceptionLarge = "q_rD s_rD";
export var Title1Small = "q_rF s_rF s_rV s_rW";
export var Title1Normal = "q_rG s_rG s_rV s_rX";
export var Title1Large = "q_rH s_rH s_rV s_rY";
export var BodySmall = "q_rJ s_rJ s_rV s_sc";
export var BodyNormal = "q_rK s_rK s_rV s_sd";
export var BodyLarge = "q_rL s_rL s_rV s_sf";